import Header from "./Reusables/Header";
import QuestionForm from "./QuestionForm";


function WhyUs(){
    

    return(
        <div className="lg:ml-40 lg:mr-40 p-5">
            <Header>Dlaczego my?</Header>
            <div className="flex flex-col justify-center">
                <p className="text-center p-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu leo lorem. Curabitur accumsan eget ante in pulvinar.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu leo lorem. Curabitur accumsan eget ante in pulvinar.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu leo lorem. Curabitur accumsan eget ante in pulvinar.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu leo lorem. Curabitur accumsan eget ante in pulvinar.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu leo lorem. Curabitur accumsan eget ante in pulvinar.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu leo lorem. Curabitur accumsan eget ante in pulvinar.
                </p>
                <p className="text-center p-4">
                    Plan każdej imprezy oraz koszty ustalamy indywidualnie!
                </p>
                <p className="text-center p-4">
                    Jeśli masz dodatkowe pytania, nie wahaj się zadać ich w formularzu poniżej! Odpowiedź otrzymasz na skrzynkę email!
                </p>
            </div>
            <QuestionForm/>
        </div>
    )
}

export default WhyUs;