import Header from "../components/Reusables/Header";
import PlaceholderPhoto from "../images/jpg/placeholder.jpg";
import "./OfferPage.scss";

function OfferPage() {
  return (
    <div className="div-container pt-40">
      <Header>Nasze oferty:</Header>
      <div className="offer-div ">
        <div className="flex shadow rounded-xl lg:w-1/2 max-lg:flex-col bg-gray-100">
          <div className="offer-photo-div">
            <img alt="placeholder" src={PlaceholderPhoto}></img>
          </div>
          <div className="offer-text-div m-auto">
            <div className="text-xl p-4 m-auto text-center">Oferta pełna</div>
            <p className="">W ramach tej oferty:</p>
            <div className="m-2">
              <li>Jakiś wielki balon</li>
              <li>Maszyna do waty</li>
              <li>Inne duperele</li>
              <li>Jeszcze więcej dupereli eee</li>
              <li>Coś fajnego</li>
              <li>Coś fajnego</li>
              <li>Coś fajnego</li>
            </div>
            <p>Koszt: 8000 zł</p>
          </div>
        </div>
      </div>

      <div className="offer-div">
        <div className="flex shadow rounded-xl lg:w-1/2 max-lg:flex-col bg-gray-100">
          <div className="offer-photo-div">
            <img alt="placeholder" src={PlaceholderPhoto}></img>
          </div>
          <div className="offer-text-div m-auto">
            <div className="text-xl p-4 m-auto text-center">Oferta pełna</div>
            <p className="">W ramach tej oferty:</p>
            <div className="m-2">
              <li>Jakiś wielki balon</li>
              <li>Maszyna do waty</li>
              <li>Inne duperele</li>
              <li>Jeszcze więcej dupereli eee</li>
              <li>Coś fajnego</li>
              <li>Coś fajnego</li>
              <li>Coś fajnego</li>
            </div>
            <p>Koszt: 8000 zł</p>
          </div>
        </div>
      </div>

      <div className="offer-div">
        <div className="flex shadow rounded-xl lg:w-1/2 max-lg:flex-col bg-gray-100">
          <div className="offer-photo-div">
            <img alt="placeholder" src={PlaceholderPhoto}></img>
          </div>
          <div className="offer-text-div m-auto">
            <div className="text-xl p-4 m-auto text-center">Oferta pełna</div>
            <p className="">W ramach tej oferty:</p>
            <div className="m-2">
              <li>Jakiś wielki balon</li>
              <li>Maszyna do waty</li>
              <li>Inne duperele</li>
              <li>Jeszcze więcej dupereli eee</li>
              <li>Coś fajnego</li>
              <li>Coś fajnego</li>
              <li>Coś fajnego</li>
            </div>
            <p>Koszt: 8000 zł</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferPage;
