import className from 'classnames';
import Header from './Reusables/Header';


function Card({text,header, imgSrc, imgAlt}){

    const classes = className('bg-gray-100 transition-all duration-300 hover:m-2 m-4 hover:p-0 shadow-md rounded')

    return (
        <div className={classes}>
            <img className='rounded' alt={imgAlt} src={imgSrc}></img>
            <Header small>{header}</Header>
            <p className='text-center m-4 '>{text}</p>
        </div>
    )
}

export default Card;