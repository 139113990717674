import "./OfferPage.scss";
import { Link } from "react-router-dom";
import {BiSad} from "react-icons/bi"


function ErrorPage() {
  return (
    <div className="div-container pt-40 flex justify-center">
          
        <div>
        <div className="flex justify-center text-2xl m-2 p-2">
            <div className="text-3xl mr-4">
              <BiSad />
            </div>
            <p>Niestety, taka zakładka nie istnieje</p>

          </div>
          <div className="text-center cursor-pointer"><Link to="/">Strona Główna</Link></div>
          </div>
          
        </div>
  );
}

export default ErrorPage;
