import { Link } from "react-router-dom";
import "./MainPage.scss";

import BirthDayIcon from "../images/svg/birthday.svg";
import WeddingIcon from "../images/svg/wedding.svg";
import EventIcon from "../images/svg/event.svg";
import ChurchIcon from "../images/svg/church.svg";
import KidsPhoto from "../images/png/kids.png";
import Button from "../components/Button";

import ContactForm from "../components/ContactForm";
import Cards from "../components/Cards";
import AboutUs from "../components/AboutUs";
import WhyUs from "../components/WhyUs";


function MainPage() {
  return (
    <div className="content">
      <div className="main-content">
        <div className="main-content-text">
          <h1 className="text-main">
            <span className="text-blue-500">Poznaj</span>{" "}
            <span className="text-yellow-500">naszą</span>{" "}
            <span className="text-pink-500">ofertę!</span>
          </h1>
          <p className="p-text">
            <img alt="birthday" src={BirthDayIcon}></img>
            <span className="text-orange-500">Urodziny</span>
          </p>
          <p className="p-text">
            <img alt="birthday" src={WeddingIcon}></img>
            <span className="text-pink-500">Wesela </span>
          </p>
          <p className="p-text">
            <img alt="birthday" src={EventIcon}></img>
            <span className="text-blue-500">Wydarzenia</span>
          </p>
          <p className="p-text">
            <img alt="birthday" src={ChurchIcon}></img>
            <span className="text-yellow-500">Komunia</span>
          </p>
          <Link to="/oferta"><Button autom>Sprawdź teraz!</Button></Link>
        </div>
        <div className="main-content-photo">
          <img alt="kids" src={KidsPhoto}></img>
        </div>
      </div>
      
      <div className="form-check-availabilty"><ContactForm/></div>
      <AboutUs/>
      <Cards/>
      <WhyUs/>
    </div>
  );
}

export default MainPage;
