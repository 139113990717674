import { useRef, useState } from "react";
import Button from "./Button";
import emailjs from "@emailjs/browser";

function QuestionForm() {
  const form = useRef();
  const [send, setSend] = useState(false);
  const [text, setText] = useState(<u>Odpowiedź otrzymasz na skrzynkę email!</u>)

  const [termin, setTermin] = useState("");
  const [email, setEmail] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    if(termin === "" || email === ""){
      setText(<p className="text-red-500 font-bold">Wypełnij wszystkie pola!</p>)
    }
    else{
      if(!send){
        setText(<p className="text-orange-500 font-bold">Wysyłanie...</p>)
        emailjs.sendForm('service_e9l7vvt','template_dkvr66q', form.current, 'fuBRN_FsIRfoVo9r3')
        .then((result) => {
          if(result.text === 'OK'){
            setSend(true);
            setText(<p className="text-lime-500 font-bold">Pomyślnie wysłałeś zapytanie, czekaj na odpowiedź!</p>)
          }
        }, (error) => {
          console.log(error.text);
        })
      }
      else{
        setText(<p className="text-red-500 font-bold">Twoje zapytanie zostało już wysłane, zrestartuj stronę jeśli chcesz wysłać kolejne!</p>)
      }
    }
    

  }

  return (
    <div className="flex flex-col">
        <form className="flex justify-center max-xl:flex-wrap" ref={form} onSubmit={sendEmail}>
        <div className="flex flex-col">
          <label>Pytanie</label>
          <input
            placeholder="np. Oferujecie dojazd do Żar?"
            className="rounded ml-2 mr-2 p-2 border"
            value={termin}
            onChange={(e) => {setTermin(e.target.value)}}
            name="termin"
          ></input>
        </div>
        <div className="flex flex-col">
          <label>Email</label>
          <input
            placeholder="np. tomek@gmail.com"
            className="rounded ml-2 mr-2 p-2 border"
            value={email}
            onChange={(e) => {setEmail(e.target.value)}}
            name="email"
          ></input>
        </div>
        <Button>Zadaj pytanie</Button>
        </form>
        <i className="flex justify-center">{text}</i>

    </div>
  );
}

export default QuestionForm;
