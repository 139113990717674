import {FaFacebookSquare, FaTiktok} from "react-icons/fa"

function Footer(){
    return(
    <div className="flex justify-between lg:pl-80 lg:pr-80 max-lg:text-sm p-5 bg-gray-200">
        <div className="flex flex-col justify-center items-center">
            <div>
            © Wszelkie prawa zastrzeżone nazwa-firmy
            </div>
            <div className="flex justify-center">
                <div className="lg:ml-5 lg:mr-5 m-2 p-2 text-4xl max-lg:text-sm"><a href="https://www.facebook.com/odlotowe.imprezy"><FaFacebookSquare/></a></div>
                <div className="lg:ml-5 lg:mr-5 m-2 p-2 text-4xl max-lg:text-sm"><a href=""><FaTiktok/></a></div>
            </div>
        </div>
        <div className="flex flex-col justify-center items-center ">
                <div>Kontakt</div>
                <p>Email: test@gmail.com</p>
                <p>Tel. : 123 123 123</p>
            </div>
            <div className="flex flex-col justify-center items-center">
            <div>Zaprojektował i wykonał Radosław Czerwiec</div>
            <div>SVG Background provided by <a target="_blank" href="https://bgjar.com">BGJar</a></div>
        </div>
    </div>)
}

export default Footer;