import "./OfferPage.scss";
import { AiOutlineMail } from "react-icons/ai";
import {TbBrandTiktok, TbBrandFacebook} from "react-icons/tb"
import {BsTelephone} from "react-icons/bs"
import {RxPerson} from "react-icons/rx"

function ContactPage() {
  return (
    <div className="div-container pt-40 flex justify-center">
          
        <div>
        <h1 className="text-4xl font-bold m-2 p-2">Skontaktuj się z nami!</h1>
        <div className="flex justify-center text-2xl m-2 p-2">
            <div className="text-3xl mr-4">
              <RxPerson />
            </div>
            <p>Piotr Nowak</p>
          </div>
          <div className="flex justify-center text-2xl m-2 p-2">
            <div className="text-3xl mr-4">
              <BsTelephone />
            </div>
            <p>123 123 123</p>
          </div>
          <div className="flex justify-center text-2xl m-2 p-2">
            <div className="text-3xl mr-4">
              <AiOutlineMail />
            </div>
            <p>Piotr@gmail.com</p>
          </div>
          <div className="flex justify-center text-2xl m-2 p-2">
            <div className="text-3xl mr-4">
              <TbBrandFacebook />
            </div>
            <p>Facebook</p>
          </div>
          <div className="flex justify-center text-2xl m-2 p-2">
            <div className="text-3xl mr-4">
              <TbBrandTiktok />
            </div>
            <p>TikTok</p>
          </div>
          <div className="flex justify-center m-2 p-2">
          <i>Kontakt telefoniczny wyłącznie w godzinach x-x</i>
          </div>
          
        </div>
    </div>
  );
}

export default ContactPage;
