import ClassNames from 'classnames';

function Button({children,autom}){

    const classes = ClassNames('p-4 mt-4 bg-blue-400 hover:bg-blue-600 rounded-full flex font-bold', {'m-auto':autom},)

    return (
        <button className={classes}>{children}</button>
    )

}

export default Button;