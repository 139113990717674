import PlaceholderPhoto from "../images/jpg/placeholder.jpg"
import Card from "./Card";
import Header from "./Reusables/Header";

function Cards() {



  return (
    <div className="lg:ml-40 lg:mr-40 p-5">
      <Header>Co gwarantujemy?</Header>
      <div className="grid grid-row-3 max-lg:grid-cols-1 grid-cols-3">
        <Card imgSrc={PlaceholderPhoto} imgAlt="placeholder" header="Dmuchańce" text="W naszej ofercie znajdziesz dwa dmuchańce - zjeżdzalnie dmuchanego trygryska oraz plac dmuchany minionek"/>
        <Card imgSrc={PlaceholderPhoto} imgAlt="placeholder" header="Wata cukrowa i popcorn!" text="Lorem ipsum dolor sit amet"/>
        <Card imgSrc={PlaceholderPhoto} imgAlt="placeholder" header="Gry dla dzieci" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu leo lorem. Curabitur accumsan eget ante in pulvinar."/>
      </div>
    </div>
  );
}

export default Cards;
