import classNames from "classnames";
import { Link, Outlet } from "react-router-dom";
import {FaFacebookSquare, FaTiktok} from "react-icons/fa"
import "./Navbar.scss";
import useScrollPosition from "../hooks/useScrollPosition";


function Navbar() {
  const scrollPosition = useScrollPosition();
  console.log(scrollPosition);

  const classes = classNames(
    "fixed flex w-full lg:pl-80 lg:pr-80 max-lg:flex-wrap max-md:justify-center lg:justify-between transition-colors",
    {
      "bg-transparent-80 shadow": scrollPosition > 100,
      "bg-transparent": scrollPosition < 100,
    }
  );

  return (
    <div className={classes}>
      <div className="logo">
        <Link to="/">Fabryka Uśmiechu</Link>
      </div>
      <div className="options">
        <Link className="option" to="/oferta">Oferta</Link>
        <Link className="option" to="/kontakt">Kontakt</Link>
        <div className="icons">
        <a href="https://www.facebook.com/odlotowe.imprezy"><FaFacebookSquare className="option"/></a>
        <a href=""><FaTiktok className="option"/></a>
      </div>
      </div>


      <Outlet />
    </div>
  );
}

export default Navbar;
