import { Routes, Route, BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ContactPage from "./pages/ContactPage";
import ErrorPage from "./pages/ErrorPage";
import MainPage from "./pages/MainPage";
import NewsPage from "./pages/NewsPage";
import OfferPage from "./pages/OfferPage";
import OtherPage from "./pages/OtherPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="content">
        <Routes>
          <Route index element={<MainPage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/oferta" element={<OfferPage />} />
          <Route path="kontakt" element={<ContactPage />} />

        </Routes>
        </div>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
