import "./AboutUs.scss"
import Header from "./Reusables/Header";

import KidsPhoto from "../images/png/kids.png";

function AboutUs() {
  return (
    <div className="lg:ml-40 lg:mr-40 p-5">
      <Header>O nas</Header>
      <div className="flex flex-rows justify-center">
        <p className="flex m-auto text-center">
        Oferujemy animacje dla dzieci w regionach Zielonej Góry, Nowej Soli, Głogowa i wielu innych miejscowości na terenie województwa Lubuskiego wliczając wioski.
        Zapewniamy animacje na weselach, urodzinach, komuniach, chrzcinach, balach karnawałowych, mikołajkowych, eventach firmowych, festynach oraz imprezazch okolicznościowych.
        
        </p>
        <div className="w-100">
          <img className="about-us-img" alt="kids" src={KidsPhoto}></img>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
